import styles from "./HeroImageCards.module.css";

function HeroImageCards({ children }) {
    return (
        <div id={styles.hero_images_overflow_container}>
            <div id={styles.hero_images}>{children}</div>
        </div>
    );
}

export default HeroImageCards;
