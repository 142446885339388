import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import styles from "./PressReleasePage.module.css";

function PressReleasePage() {
    return (
        <div>
            <Header dark={true} />
            <main id={styles.main_wrapper}>
                <div id={styles.main}>
                    <h1 className="main_h1">
                        Keel Mind and thymia Announce Partnership to Advance
                        Mental Health Assessments
                    </h1>

                    <div id={styles.body}>
                        <h2>FOR IMMEDIATE RELEASE</h2>{" "}
                        <p>
                            [Thursday, December 19th] – In a significant
                            advancement for mental health care, Keel Mind, a
                            leader in AI-driven mental health training and
                            assessments, has announced a strategic partnership
                            with thymia, the pioneering firm in
                            neuropsychology-driven mental health technology.
                            This collaboration aims to transform how mental
                            health is measured and managed, particularly among
                            first responders.
                        </p>
                        <p>
                            Through the integration of thymia’s cutting-edge
                            machine learning and neuropsychology technologies
                            into its readiness checks, Keel Mind now offers an
                            enhanced tool for evaluating mental wellness.
                            Thymia’s assessments, recognized for their precision
                            and ease of use, provide a level of accuracy and
                            robustness in stress and fatigue measurement that
                            surpasses conventional tools like those offered by
                            Oura Ring, Garmin and Apple Watch.
                        </p>
                        <p>
                            <strong>
                                Advancing Mental Health with Innovative
                                Technology
                            </strong>{" "}
                            Keel Mind is dedicated to transforming the landscape
                            of mental health care across various sectors,
                            including academia, indigenous communities, first
                            responders, and clinical practices. By leveraging
                            thymia’s advanced technologies, Keel Mind enhances
                            its robust software platform, which offers secure
                            video conferencing, comprehensive progress notes,
                            peer-to-peer connections, advanced analytics, and
                            curated content. This integration empowers these
                            diverse groups with the tools they need to
                            effectively manage and support mental wellness
                            within their unique environments.
                        </p>
                        <p>
                            <strong>Ensuring Privacy and Security</strong> Both
                            Keel Mind and thymia uphold the highest standards of
                            data security and privacy, ensuring that all health
                            data remains confidential and securely protected.
                        </p>{" "}
                        <p>
                            Ahad Bandealy, Founder of Keel Mind, emphasizes the
                            transformative potential of our partnership with
                            Thymia: 'This collaboration marks a pivotal
                            advancement in mental health care. Together, we are
                            establishing unprecedented standards for both
                            accuracy and convenience in mental health
                            assessments. Our joint efforts will significantly
                            enhance the mental well-being of our clients.'
                        </p>
                        <p>
                            Emilia Molimpakis, CEO at thymia, added, Our
                            partnership with Keel marks a significant milestone
                            in our mission to make mental health as objectively
                            measurable as physical health, bringing
                            clinical-grade mental health biomarkers to those who
                            need them most. We are particularly excited to
                            extend our technology to first responders and
                            indigenous communities through this collaboration,
                            as it perfectly aligns with our commitment to making
                            mental health monitoring accessible and inclusive
                            for everyone, everywhere.
                        </p>
                        <p>
                            <strong>About Keel Mind</strong> Keel Mind is a
                            software platform focusing on mental health, peer
                            support, and education. Working with educational
                            institutions, indigenous communities, first
                            responders, and clinical practices, Keel Mind
                            provides secure video conferencing, progress notes,
                            peer-to-peer interactions, advanced analytics, and
                            curated content to support our current and future
                            clients.
                        </p>
                        <p>
                            <strong>About thymia</strong> Thymia is leading the
                            charge in revolutionizing mental health diagnostics,
                            utilizing the latest advancements in AI and
                            neuropsychology to quantify mental wellness
                            accurately. Through pioneering voice biomarker
                            technology, thymia assesses mental wellbeing in
                            seconds, making mental health monitoring as simple
                            and objective as checking your blood pressure.
                        </p>
                        <p>
                            For more information about how Keel Mind and thymia
                            are enhancing mental health assessments and support,
                            please visit{" "}
                            <a href="www.keelmind.com">keelmind.com</a> or{" "}
                            <a
                                href="www.thymia.ai"
                                target="_blank"
                                rel="noreferrer"
                            >
                                www.thymia.ai
                            </a>
                            .
                        </p>
                        <p>
                            <strong>Contact Information:</strong> Julia Hanaka,
                            Director of Sales and Marketing
                        </p>
                        <p>
                            Keel Mind and thymia are excited to continue their
                            journey towards improving mental health support,
                            looking forward to making a lasting impact in the
                            community.
                        </p>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default PressReleasePage;
